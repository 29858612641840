import React from 'react'
import { Helmet } from "react-helmet"
import thumbnail from '../../static/thumbnail.png'
import Header from '../components/Header'
import Footer from '../components/Footer'

const NotFoundPage = () => {
    return(
        <React.Fragment>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                <meta charSet="utf-8" />
                {/* Robot Meta Tags */}
                <meta name="robots" content="noindex,nofollow,noarchive" />

                {/* HTML Meta Tags */}
                <title>Food Quickie | Page Not Found</title>
                <meta name="description" 
                content="Welcome to Food Quickie! The page you're trying to reach doesn't exist." />

                {/* Google / Search Engine Tags */}
                <meta itemprop="name" content="Food Quickie | Page Not Found" />
                <meta itemprop="description" content="Welcome to Food Quickie! The page you're trying to reach doesn't exist." />
                <meta itemprop="image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Food Quickie | Page Not Found" />
                <meta property="og:description" content="Welcome to Food Quickie! The page you're trying to reach doesn't exist." />
                <meta property="og:image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Food Quickie | Page Not Found" />
                <meta name="twitter:description" content="Welcome to Food Quickie! The page you're trying to reach doesn't exist." />
                <meta name="twitter:image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

            </Helmet>
            <Header />
                <div className="row not-found-container">
                    {/* <div className="error-div "> */}
                        <div className="error-code">
                            ERROR 404
                        </div>
                        <div className="error-heading">
                            The page you're trying to reach doesn't exist.
                        </div>
                    {/* </div> */}
                </div>
            <Footer />
        </React.Fragment>
    )
}
  
export default NotFoundPage;