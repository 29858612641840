import React, { Component } from 'react'
import NavigationBar from '../components/NavigationBar'

class Header extends Component {
    render() {
        return(
            <header className="not-found-header">
                <NavigationBar hide />
            </header>
        )
    }
}

export default Header